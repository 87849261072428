<template>
  <h1 v-if="false">Mesh DeepLink</h1>
</template>

<script>
export default {
  name: "MeshDeepLink",
  async beforeMount () {
    this.$store.dispatch("OpenMesh/setOpenMesh", true)
    this.$router.push("/home")
  }
}
</script>

<style>

</style>
